.container {
  width: 100%;
  padding: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 844px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 25px;
  font-family: Poppins, sans-serif;
  text-shadow: 0px 4px 4px 0px #00000040;
  color: #fff;
  margin-top: 6px;
  margin-bottom: 12px;
  text-align: center;

  @media screen and (min-width: 678px) {
    text-align: center;
  }
}

.inputWrapper {
  width: 100%;
  max-width: 420px;
  position: relative;

  @media screen and (min-width: 678px) {
    width: auto;
  }
}

.arrowLeft {
  position: absolute;
  top: -50%;
  left: 0;
  transform-origin: bottom left;
  transform: scale(0.7) translate(-102%, -60%);

  @media screen and (min-width: 1280px) {
    transform: scale(1) translate(-110%, -40%);
  }
}

.arrowRight {
  position: absolute;
  top: -50%;
  right: 0;
  transform-origin: bottom right;
  transform: scale(0.7) translate(102%, -60%);

  @media screen and (min-width: 1280px) {
    transform: scale(1) translate(110%, -40%);
  }
}

.input {
  width: 100%;
  height: 45px;
  border: 2px solid #b4c4ff;
  border-radius: 13px;
  padding-left: 24px;

  &::placeholder {
    font-family: D-DIN, sans-serif;
    font-size: 16px;
  }

  @media screen and (min-width: 678px) {
    width: 420px;
  }
}

.button {
  border: 2px solid var(--logo-gold, #a98242);
  background: linear-gradient(180deg, #ffcc77 0%, #a98242 100%);
  width: 100%;
  color: #fff;
  max-width: 420px;
  margin-top: 12px;
  white-space: break-spaces;
  font-family: D-DIN, sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-shadow: 0px -2px 2px rgba(0, 0, 0, 0.4);
  border-radius: 13px;
  padding: 6px 12px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-4px);
  }
}

.submissionText {
  color: #fff;
  margin-top: 12px;
}
