.container {
  width: 100%;
  position: relative;
}

.bannerImage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}

.content {
  max-width: 844px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 24px 0 24px;

  @media screen and (min-width: 886px) {
    padding: 24px 0 0 0;
  }
}

.header {
  width: 100%;
  text-align: center;

  h4 {
    margin-top: 0;
    margin-bottom: 0;
    color: #ffd287;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 15px;
    margin: 0 -12px 12px -12px;

    @media screen and (min-width: 324px) {
      font-size: 15px;
    }

    @media screen and (min-width: 400px) {
      font-size: 18px;
    }

    @media screen and (min-width: 678px) {
      font-size: 20px;
      margin: 0;
    }
  }

  p {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin: 8px -12px 0 -12px;
    color: #fff;

    @media screen and (min-width: 400px) {
      font-size: 18px;
      margin: 8px 0 0 0;
    }
  }
}

.isBackText {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  text-align: left;
  margin: 0;

  @media screen and (min-width: 420px) {
    font-size: 20px;
  }
}

.boxTitle {
  background: linear-gradient(180deg, #a98242 18.75%, #62410b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Poppins;
  font-size: 22px;
  font-style: italic;
  font-weight: 800;
  text-align: left;
  margin: 0;
  line-height: 32px;

  @media screen and (min-width: 420px) {
    font-size: 24px;
    line-height: 34px;
  }

  @media screen and (min-width: 678px) {
    font-size: 30px;
    line-height: 42px;
  }
}

.box {
  width: 100%;
  display: flex;
  gap: 6px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 10px;
  margin-top: 24px;
  border-radius: 30px;
  background: linear-gradient(180deg, #d9cfbf 0%, #a98242 100%);
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    background-image: linear-gradient(180deg, #ffffff 0%, #f1f1f1 100%);
    padding: 10px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 30px;
    top: 2px;
    left: 2px;
    position: absolute;
    z-index: -1;
  }

  @media screen and (min-width: 678px) {
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: center;
    padding: 13px 14px 13px 0;
  }
}

.bitcoin {
  max-width: 80px;

  @media screen and (min-width: 440px) {
    max-width: 110px;
  }
}

.grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  gap: 12px;

  @media screen and (min-width: 678px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.numberOneTitle {
  color: #ffd287;
  font-family: Poppins, sans-serif;
  font-size: 23px;
  font-weight: 800;
  line-height: 32px;
  text-align: center;
  margin: 12px -12px 12px -12px;

  @media screen and (min-width: 368px) {
    font-size: 26px;
    line-height: 34px;
  }

  @media screen and (min-width: 400px) {
    font-size: 28px;
    line-height: 36px;
  }

  @media screen and (min-width: 678px) {
    font-size: 30px;
    text-align: left;
    line-height: 42px;
    margin: 0 0 12px 0;
  }
}

.numberOneDesc {
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #fff;
  margin: 0 -12px 0 -12px;

  @media screen and (min-width: 346px) {
    font-size: 16px;
  }

  @media screen and (min-width: 400px) {
    font-size: 18px;
  }

  @media screen and (min-width: 460px) {
    margin: 0;
  }

  @media screen and (min-width: 678px) {
    text-align: left;
    padding-bottom: 12px;
  }
}

.teekaImage {
  align-self: center;
  max-width: 240px;

  @media screen and (min-width: 678px) {
    align-self: flex-end;
  }
}

.hiddenMobileText {
  display: none;

  @media screen and (min-width: 678px) {
    display: inline;
  }
}
