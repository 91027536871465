.container {
  width: 100%;
  max-width: 844px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;

  @media screen and (min-width: 844px) {
    padding: 0;
  }
}

.title {
  text-align: center;
  color: #4b5c9d;
  font-family: Poppins, sans-serif;
  font-size: 32px;
  font-weight: 800;
  line-height: 43px;
  margin: 24px 0 12px 0;
}

.row {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 38px;
  align-items: center;
  margin-bottom: 24px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;

  @media screen and (min-width: 678px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.gridItem {
  width: 100%;
  min-height: 240px;
  box-shadow: 0px 11px 15px 0px #00000026;
  background: linear-gradient(130.17deg, #43569f 4.16%, #181f39 91.56%);
  padding: 40px 46px 40px 46px;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
}

.gridItemText {
  font-size: 18px;
  font-family: Poppins, sans-serif;
  font-style: italic;
  line-height: 25px;
  color: #fff;
  text-align: center;
}
