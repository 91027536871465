@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;0,800;1,400;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.fullWidth {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin: -1vw -50vw;
}

@font-face {
  font-family: "D-DIN";
  src: url("./assets/fonts/D-DIN.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "D-DIN";
  src: url("./assets/fonts/D-DIN-Bold.woff2") format("woff2");
  font-weight: 700;
}
