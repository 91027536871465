.container {
  overflow: hidden;
  padding-bottom: 64px;
}

.newsletterWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(294.16deg, #1f2c5c 38.06%, #415dc2 87.22%);
  padding: 10px 0 25px 0;
  transform: translateY(-4px);
}

.newsletterBackground {
  max-width: 844px;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(294.16deg, #1f2c5c 38.06%, #415dc2 87.22%);
  border-radius: 30px;
  margin-top: 24px;
}

.newsletterContent {
  padding: 0 24px;

  @media screen and (min-width: 884px) {
    padding: 0;
  }
}

.newsletterText {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 25.6px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px 0px #00000040;
}
